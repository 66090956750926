<template>
  <div class='page'>
    <div class='breadcast'
         style="text-align:right">
      <el-button type='primary'
                 @click='$router.push("/collectorAttence")'
                 size='medium'>返回</el-button>
    </div>
    <div class='calendar-wrapper'>
      <div class='calendar-header'>
        <el-button type='text'
                   @click='monthReduce'
                   style="color:#666666;font-size:16px"
                   class='el-icon-arrow-left'></el-button>
        <span style="color:#48709E;font-size:16px;margin-left:28px">{{currentYear + '年   '}}</span><span style="color:#48709E;font-size:16px;margin-left:39px;margin-right:28px">{{currentMonth*1 + 1 + '月'}}</span>
        <el-button type='text'
                   @click='monthAdd'
                   style="color:#666666;font-size:16px"
                   class='el-icon-arrow-right'></el-button>
        <div class='backToday'>
          <el-button type='text'
                     @click='backToday'>返回今日</el-button>
        </div>
      </div>
      <ul class='calendar'>
        <li class='calendar-day'>周一</li>
        <li class='calendar-day'>周二</li>
        <li class='calendar-day'>周三</li>
        <li class='calendar-day'>周四</li>
        <li class='calendar-day'>周五</li>
        <li class='calendar-day'>周六</li>
        <li class='calendar-day'>周日</li>
      </ul>
      <ul class='month-day-list'>
        <li class='month-day'
            v-for='v in dateArr'
            :key='v&&v.value'
            :class='v&&v.className || ""'
            @click='v&&v.click()'>
          <div style='padding-left:20px;padding-top:20px'>
            <span class='el-icon-success mark'
                  v-if='v&&v.f'
                  style='color:rgba(86,189,91,1)'></span>
            <span class='el-icon-warning mark'
                  v-if='v&&v.s'
                  style='color:rgba(240,66,91,1)'></span>
          </div>
          <div class='month-day-value'>{{v&&v.value || v&&v.formatter}}</div>
        </li>
      </ul>
    </div>

  </div>
</template>
<script>
import {
  // dateFormat
} from '@/common/js/public'
export default {
  data() {
    let date = this.$route.query.date.split('-');
    let year = date[0];
    let month = date[1] - 1;
    return {
      today: new Date(),
      currentDay: new Date().getDay(), // 当前的星期
      currentDate: new Date().getDate(), // 当前的日期
      currentYear: year, // 当前的年份
      currentMonth: month, // 当前的月份
      dateArr: new Array(35),
      dayCols: {}
      //    dayCols: {
      //        1: {
      //            className: 'red'
      //        },
      //        3: {
      //            s: true
      //        },
      //        10: {
      //            f: true,
      //            className: 'cursor',
      //            click: (data) => {
      //                this.$router.push({
      //                    path: '/attenceDetail',
      //                    query: {
      //                        year: this.currentYear,
      //                        month: this.currentMonth,
      //                        day: data
      //                    }
      //                })
      //            }
      //        },
      //        20: {
      //            className: 'cursor',
      //            click: (data) => {
      //                alert(data)
      //            }
      //        }
      //    }
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    init() {
      this.getDetail();
    },
    getDetail() {
      let month = ''
      let currentMonth = this.currentMonth + 1;
      if (currentMonth < 10) {
        month = this.currentYear + '-0' + currentMonth
      } else {
        month = this.currentYear + '-' + currentMonth
      }
      let url = '/acb/2.0/pdaManager/attend/monthList/' + month + '/' + this.$route.query.pdaManagerId;
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          let dayCols = {};
          res.value.forEach(v => {
            let f = false;
            let s = false;
            if (v.signinTime && v.signoutTime) {
              f = true;
            } else {
              s = true;
            }
            let day = v.signDate.split(' ')[0].split('-')[2] * 1;
            dayCols[day] = {
              f,
              s,
              className: 'cursor',
              click: (data) => {
                this.$router.push({
                  path: '/attenceDetail',
                  query: {
                    year: this.currentYear,
                    month: this.currentMonth + 1,
                    day: data,
                    pdaManagerId: this.$route.query.pdaManagerId
                  }
                })
              }
            }
          })
          this.dayCols = dayCols;
          this.drawCalendar(this.currentYear, this.currentMonth);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    monthReduce() {
      this.currentMonth--;
      if (this.currentMonth == -1) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.init()
    },
    monthAdd() {
      this.currentMonth++;
      if (this.currentMonth == 12) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.init()
    },
    backToday() {
      this.currentYear = new Date().getFullYear();
      this.currentMonth = new Date().getMonth();
      this.init()
    },
    drawCalendar(year = new Date().getFullYear(), month = new Date().getMonth()) {
      this.dateArr = new Array(42);
      let Odate = new Date('2018/1/1');
      Odate.setMonth(month); // 月份和星期都是从0 开始的
      Odate.setFullYear(year);
      Odate.setDate(1);
      Odate.setHours(0);
      Odate.setMinutes(0);
      Odate.setSeconds(0);
      Odate.setMilliseconds(0);
      let temDay = Odate.getDay(); // 获取第一天的星期
      let line = 1; // 第几行
      let calDay = 1;
      let ind = temDay;
      do {
        if (temDay == 0) {
          temDay = 7;
          line = 1;
        } else if ((temDay - 1) % 7 == 0 && temDay != 1) { // 下一行的第一个数字 使行数加1
          line++
        }
        let temVal = 0;
        let temCalDay = calDay; // 把第几天保存起来,用于处理点击事件传参
        // 设置周日的位置
        let curDay = Odate.getDay(); // 当前设置的日期的星期数
        if (curDay == 0) {
          ind = (line - 1) * 7 + 6;
        } else {
          ind = (line - 1) * 7 + curDay - 1;
        }
        // 小于10  补0
        if (calDay < 10) {
          temVal = '0' + calDay;
        } else {
          temVal = calDay;
        }
        this.dateArr[ind] = {
          value: temVal,
          className: this.dayCols[calDay] && this.dayCols[calDay].className,
          formatter: this.dayCols[calDay] && this.dayCols[calDay].fn && this.dayCols[calDay].fn(calDay),
          f: this.dayCols[calDay] && this.dayCols[calDay].f,
          s: this.dayCols[calDay] && this.dayCols[calDay].s,
          click: () => {
            if (this.dayCols[temCalDay] && this.dayCols[temCalDay].click) {
              this.dayCols[temCalDay].click(temCalDay)
            }
          }
        };
        calDay++; // 天数递增
        Odate.setDate(calDay);
        temDay++;
      } while (Odate.getMonth() == month)
    }
  },
  created() {
    this.init();
  },
  mounted() {
  }
}
</script>
<style scoped>
.breadcast {
  height: 30px;
}
.calendar-wrapper {
  padding-top: 10px;
}
.calendar-day {
  float: left;
  width: 14.2857%;
  text-align: center;
  height: 50px;
  box-sizing: border-box;
}
.month-day {
  float: left;
  width: 14.2857%;
  height: 100px;
  box-sizing: border-box;
  border-right: 1px solid #e0e6ed;
  border-bottom: 1px solid #e0e6ed;
  position: relative;
}
.calendar {
  background: rgba(224, 230, 237, 1);
  line-height: 48px;
  border-right: 1px solid #e0e6ed;
}
.month-day-list,
.calendar {
  overflow: hidden;
  border-left: 1px solid #e0e6ed;
  border-top: 1px solid #e0e6ed;
}
.red {
  background: red;
}
.month-day-value {
  font-size: 14px;
  font-family: PingFangSC-Light;
  color: rgba(153, 153, 153, 1);
  line-height: 20px;
  position: absolute;
  bottom: 12px;
  right: 10px;
  box-sizing: border-box;
}
.mark {
  font-size: 30px;
}
.calendar-header {
  line-height: 72px;
  text-align: center;
  border: 1px solid #e0e6ed;
  border-bottom: 0;
  position: relative;
}
.backToday {
  position: absolute;
  right: 20px;
  top: 0;
}
.cursor {
  cursor: pointer;
}
</style>
